.Quill {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.VariableBar {
  display: flex;
}

.MaterialRow {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
}
