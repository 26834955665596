.MappingForm {
  display: grid;
  gap: var(--spacing-medium) var(--spacing-small);
  grid-template-columns: 1fr auto 1fr auto;
  align-items: center;
}

.ActionContainer {
  grid-column: 1 / 5;
  display: flex;
  &.JustifyEnd {
    justify-content: flex-end;
  }
}

.AddButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-small) var(--spacing-large);
  text-align: center;
  width: 100%;
  border-radius: var(--rounding-small);
  cursor: pointer;
  box-sizing: border-box;
  svg {
    margin-right: var(--spacing-medium);
  }
  &:hover {
    background: var(--palette-background-hovered);
  }
  &:active {
    background: var(--palette-background-pressed);
  }
}
