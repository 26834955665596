.Root {
  background: var(--palette-surface-subdued);
  border-radius: var(--rounding-medium);
  padding: var(--spacing-large);
}

.error {
  background: var(--palette-danger-ghosted);
}

.Item {
  display: flex;
  align-items: center;
  gap: var(--spacing-medium);
  padding: var(--spacing-xSmall) 0;

  .Title {
    background: var(--palette-background-default);
    padding: var(--spacing-small) var(--spacing-medium);
    border-radius: var(--rounding-small);
    border: 1px solid var(--palette-border-subdued);
    flex: 1;
  }
}
