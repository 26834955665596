.PersonList {
  display: grid;
  grid-template-columns: 1fr 24px 24px;
  align-items: center;
  column-gap: var(--spacing-small);
  row-gap: var(--spacing-medium);
}

.PersonListItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  margin-left: calc(var(--spacing-medium) - 2px);
  padding: 2px;
}

.Indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: var(--palette-primary-default);
}