.Block {
  padding: var(--spacing-xLarge);
  border-bottom: 1px solid var(--palette-border-subdued);

  &.Blended {
    border-bottom: none;
    padding-bottom: var(--spacing-small);
  }
  &.Compact {
    padding: var(--spacing-medium) var(--spacing-xLarge);
    padding-bottom: 0;
  }
}
