.ql-toolbar.ql-snow {
  font-family: "Poppins", sans-serif;
  border-radius: var(--rounding-small);
  border: none;
  margin-bottom: 4px;
  background-color: var(--palette-surface-subdued);
  .ql-formats button {
    color: rgb(22, 22, 22) !important;
    &:hover {
      color: rgb(13, 72, 210) !important;
    }
    &.ql-active {
      color: rgb(13, 72, 210) !important;
    }
  }
  &.readonly {
    display: none;
  }
}


.quill.error {
  .ql-container {
    background: var(--palette-danger-ghosted);
  }
}


.ql-container.ql-snow {
  font-family: "Poppins", sans-serif;
  border-radius: var(--rounding-small);
  border: none;
  background: var(--palette-surface-subdued);
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.ql-container.ql-snow.ql-disabled {
  border-radius: 6px;
  background-color: transparent;
  color: rgb(90, 90, 90);
  border-color: rgba(0, 0, 0, 0);
}

.readonly.light .ql-container.ql-snow.ql-disabled {
  background-color: transparent;
  color: var(--theme-colors-typography);
  font-size: 0.875rem;
}

.readonly.light .ql-container.ql-snow.ql-disabled .ql-editor {
  padding: 0;
  min-height: 0;
}

.ql-hidden {
  display: none !important;
}

.ql-snow .ql-tooltip {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  display: flex;
  border-top-width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  box-shadow: none !important;
  margin-top: 0 !important;
}

.ql-tooltip.ql-editing input {
  flex: 1;
}

.ql-tooltip.ql-editing input::placeholder {
  visibility: hidden;
}

.ql-editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.ql-custom {
  background: red;
}

field {
  white-space: nowrap;
  padding: 0rem 0.5rem;
  border-radius: var(--rounding-medium);
  border: none;
  text-align: center;
  &:focus {
    outline-style: none;
  }
  span {
    display: hidden;
  }
  background: var(--palette-primary-ghosted);
  color: var(--palette-primary-default);
  font-weight: bold;
}

tag.ql-custom-userproperty,
tag.ql-custom-customproperty,
tag.ql-custom-material {
  white-space: nowrap;
  padding: 0rem 0.5rem;
  border-radius: var(--rounding-medium);
  border: none;
  text-align: center;
  &:focus {
    outline-style: none;
  }
  span {
    display: hidden;
  }
}

tag.ql-custom-userproperty,
tag.ql-custom-customproperty {
  background: var(--palette-primary-ghosted);
  color: var(--palette-primary-default);
  font-weight: bold;
}

tag.ql-custom-material {
  background: var(--palette-purple-ghosted);
  color: var(--palette-purple-default);
  font-weight: bold;
}

.giphy-select {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  border-color: rgb(227, 227, 227);
  padding: 12px;
}

.giphy-select input {
  border-color: rgb(227, 227, 227);
  padding: 8px;
  border-radius: 4px;
}

.giphy-select input:focus {
  border-color: rgb(227, 227, 227);
}

.giphy-select input:placeholder {
  color: rgb(227, 227, 227);
}

.ql-materials {
  &:focus {
    outline: none;
  }
  &:hover,
  &.ql-active {
    h5 {
      color: rgb(13, 72, 210) !important;
      svg {
        fill: rgb(13, 72, 210) !important;
      }
    }
  }
  svg {
    fill: rgb(22, 22, 22) !important;
  }

  h5 {
    color: rgb(22, 22, 22) !important;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  color: var(--palette-foreground-dimmed) !important;
}
