.AIWriter {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--spacing-xLarge);
    padding-top: var(--spacing-xLarge);
    align-items: flex-end;
    width: 920px;
}

.Container {
    display: flex;
    gap: var(--spacing-large);
    flex-direction: column;

}

.Prompts {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing-medium) var(--spacing-xLarge);
    align-items: center;
}


.FreeText {
    display: flex;
    gap: var(--spacing-medium);
    flex-direction: column;
}

.Actions {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: flex-end;
    margin-left: auto;
}