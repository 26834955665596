.LoginView {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: var(--palette-surface-subdued);
    justify-content: center;
    align-items: center;
}

.LoginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: var(--spacing-xLarge);
}

.GoogleButton {
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    max-height: 48px;
}

.LogoContainer {
    position: absolute;
    top: 40px;
}

.LoginCard {
    padding: 160px 32px 60px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium);
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-width: 16rem;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
}

.CardEffect {
    width: 500px;
    height: 300px;
    background-color: #105EFB;
    position: absolute;
    top: -180px;
    border-radius: 50%;

}

.EmailContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium);
}