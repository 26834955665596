.Preview {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
}

.Versions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-small);
  align-items: center;
  justify-content: center;
}

.Actions {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-end;
  margin-left: auto;
}

.Editor {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
}

.HtmlEditor {
  max-height: 250px;
}
