.Events {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  margin-top: var(--spacing-xLarge);
  align-items: center;
  width: 100%;
}

.Card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Title {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xSmall);
  }
}