.Root {
  width: 64px;
  height: 64px;
  border-radius: var(--rounding-medium);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 64px;
    height: 64px;
    border-radius: var(--rounding-small);
    cursor: pointer;
  }
}

.Dropzone {
  border: 1px dashed var(--palette-border-default);
  background: var(--palette-surface-subdued);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: var(--palette-surface-default);
    border-color: rgba(0, 0, 0, 0);
  }
}
