.tiptap {
    outline: none !important;
    width: 100%;
    min-height: 200px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: scroll;
    max-width: 100%;
    font-family: var(--typography-font-text) !important;
    field[data-property-field] {
        background-color: var(--palette-primary-ghosted); /* Light blue background */
        color: var(--palette-primary-default); /* Text color to match the border */
        padding: 0 var(--spacing-xSmall); /* Spacing inside the field */
        border-radius: var(--rounding-small); /* Rounded corners */
        display: inline-block; /* Inline element styling */
        font-family: var(--typography-font-text); /* Consistent font */
        font-size: 90%; /* Slightly smaller text size */
        cursor: default; /* Prevent text-selection cursor */
      }
      
      /* Ensure the field is non-editable */
      field[data-property-field][contenteditable="false"] {
        user-select: none; /* Prevent text selection */
        pointer-events: none; /* Disable interaction */
      }
      
      /* Style for the nested <span> inside <field> */
      field[data-property-field] span {
        color: var(--palette-primary-hovered); /* Slightly darker blue for contrast */
        font-weight: bold; /* Make the text bold */
        text-decoration: none; /* Remove any default underline */
      }
      img {
        max-width: 100%;
      }
}

.tiptap p {
    margin: var(--spacing-medium) 0;
}

