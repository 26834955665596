@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cm-focused {
  outline: none !important;
}

:root {
  --fc-border-color: var(--palette-border-subdued);
  --fc-daygrid-event-dot-width: 5px;
}
