

.GlowingStars {
    position: relative;
    display: flex;
    width: 16px;
    height: 16px;
    margin-right: var(--spacing-small);

    & > svg {
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    & svg.first {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        animation-name: glow;
    }
    & svg.second {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        animation-name: glow-grow;
    }
}



.GlowingButton {
    border: 1px solid var(--palette-primary-dimmed);
    background: var(--palette-background-default);
    border-radius: var(--rounding-small);
    padding: var(--spacing-small) var(--spacing-medium);
    color: var(--palette-foreground-default);
    font-size: var(--typography-size-title);
    font-family: var(--typography-font-text);
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px 0px rgb(16 94 251 / 10%);
    transition: all ease-in 0.25s;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 1px 4px 0px rgb(16 94 251 / 50%);
    }
}

@keyframes glow {
    0% {
        transform: scale(1, 1);
        transform-origin: 0% 0% 0px;
    }



    50% {
        transform: scale(0.5, 0.5);
        transform-origin: 50% 50% 0px;
    }



    100% {
        transform: scale(1, 1);
        transform-origin: 0% 0% 0px;
    }
}

@keyframes glow-grow {
    0% {
        transform: scale(0.5, 0.5);
        transform-origin: 50% 50% 0px;
    }



    50% {
        transform: scale(1, 1);
        transform-origin: 0% 0% 0px;
    }



    100% {
        transform: scale(0.5, 0.5);
        transform-origin: 50% 50% 0px;
    }
}