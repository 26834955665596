.Root {
  background: var(--palette-background-default);
}

.Block {
  padding: var(--spacing-xLarge);
  border-bottom: 1px solid var(--palette-border-subdued);
  &.Blended {
    border-bottom: none;
    padding-bottom: var(--spacing-small);
  }
}

.DrawerGrid {
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: var(--spacing-small);
  row-gap: var(--spacing-medium);
  align-items: center;
  justify-content: flex-start;
}